
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Flex from 'shared/library/atoms/Flex';
import SkeletonLoader, { SkeletonRectangle } from 'shared/library/atoms/SkeletonLoader';
const CarouselSlideSkeleton = () => (<SkeletonLoader>
    <Flex flexDirection="column" gap={3} width={320}>
      <SkeletonRectangle width={320} height={160}/>
      <SkeletonRectangle width={1} height={24}/>
      <SkeletonRectangle width={1} height={24}/>
    </Flex>
  </SkeletonLoader>);
export const SubtitleSlideSkeleton = () => (<SkeletonLoader>
    <SkeletonRectangle width={150} height={24}/>
  </SkeletonLoader>);
export default CarouselSlideSkeleton;

    async function __Next_Translate__getStaticProps__19330172ec0__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'library//EolaMarketplace/EolaMarketplace.skeleton',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19330172ec0__ as getStaticProps }
  